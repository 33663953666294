import defaultAxios from "axios"
import Router from "next/router"
import { useSessionState } from "core/hooks/use-session-state"
import toast from "react-hot-toast"
import { type TypedAxios } from "typed-axios-instance"
import { Routes } from "src/all-routes"

export const useUnauthenticatedAxios = () => {
  return defaultAxios.create({
    baseURL: process.env.NEXT_PUBLIC_API_HOST,
  })
}

export const getAuthenticatedAxios = (
  workspace_id = ""
): TypedAxios<Routes> => {
  const sessionKey = useSessionState.getState().sessionToken
  const axios = defaultAxios.create({
    baseURL: process.env.NEXT_PUBLIC_API_HOST,
    headers: {
      Authorization: "Bearer " + sessionKey,
      "Seam-Workspace": workspace_id,
    },
  })

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status == 401) {
        setTimeout(() => {
          Router.push("/signout")
        }, 2000)
        toast.error("Your session has expired. Redirecting to sign in...", {
          id: "session-expired",
        })
      }
      return Promise.reject(error)
    }
  )

  return axios
}

export const useAuthenticatedAxios = (workspace_id?: string) => {
  return getAuthenticatedAxios(workspace_id)
}
