export const seamColors = {
  transparent: "transparent",
  current: "currentColor",
  black: "#232426",
  white: "#FFFFFF",
  primary: {
    faint: "#EBEBFF",
    lightest: "#C4B7FD",
    light: "#7958F4",
    DEFAULT: "#592BFF",
    dark: "#2F0F9F",
    darkest: "#1B095E",
    black: "#140230",
  },
  secondary: {
    faint: "#DCFFEE",
    lightest: "#8CFEC7",
    light: "#4DF2A3",
    DEFAULT: "#1ED27C",
    dark: "#12AE63",
    darkest: "#07713E",
    black: "#063820",
  },
  tertiary: {
    faint: "#F0FBFF",
    lightest: "#CAF2FF",
    light: "#90E4FF",
    DEFAULT: "#10C6FF",
    dark: "#0EAFE1",
    darkest: "#0683AA",
    black: "#144C5E",
  },

  error: {
    faint: "#FFD4D9",
    lightest: "#FF7E8E",
    light: "#FA5064",
    DEFAULT: "#D23043",
    dark: "#A62B3B",
    darkest: "#62131D",
    black: "#33060A",
  },
  warning: {
    faint: "#FFD8CA",
    lightest: "#FF9773",
    light: "#FF6732",
    DEFAULT: "#FF4605",
    dark: "#D2420F",
    darkest: "#8D3212",
    black: "#441403",
  },
  gray: {
    faint: "#f8fafc",
    lightest: "#f1f5f9",
    light: "#E2E8F0",
    DEFAULT: "#7591A3",
    dark: "#475569",
    darkest: "#1e293b",
  },
  rust: {
    faint: "#FFF6EF",
    lightest: "#ECDED4",
    light: "#DCCFC6",
    DEFAULT: "#BCA899",
    dark: "#8D7869",
    darkest: "#564A41",
    black: "#2E2722",
  },
}
