import { useSaveIsAuthenticated } from "core/hooks/use-is-authenticated"
import { useClerk } from "@clerk/nextjs"
import { useSessionState } from "core/hooks"

export const useSignOut = () => {
  const saveIsAuthenticated = useSaveIsAuthenticated()

  let signOut: undefined | ReturnType<typeof useClerk>["signOut"]
  try {
    signOut = useClerk().signOut
  } catch (e) {
    // clerk not loaded...
  }

  return async () => {
    saveIsAuthenticated(false)
    if (signOut) await signOut()
    useSessionState.persist.clearStorage()
    window.location.href = "/signin"
  }
}
