import { useEffect } from "react"
import { useRouter } from "next/router"
import { useIsAuthenticated } from "core/hooks/use-is-authenticated"

export const useRequireAuthentication = () => {
  const isAuthenticated = useIsAuthenticated()
  const { push } = useRouter()

  useEffect(() => {
    if (!isAuthenticated) {
      push("/signin")
    }
  }, [isAuthenticated, push])
}
