import { useMe } from "api-hooks/use-me"
import { useEffect } from "react"
import { useIntercom } from "react-use-intercom"

export const RegisterUserWithIntercom = () => {
  const { data: me } = useMe()

  const intercom = useIntercom()

  useEffect(() => {
    if (!me) return
    intercom.boot({
      email: me.email,
      name: `${me.first_name} ${me.last_name}`,
      userId: me.user_id,
    })
  }, [me?.email])

  return null
}
