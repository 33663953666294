import { useState, useEffect } from "react"

export const useMemoIf = <T>(
  computeValue: () => T,
  updateIf: Array<any>,
  condition: boolean
): T => {
  const [memo, setMemo] = useState(computeValue())

  useEffect(() => {
    if (condition) {
      setMemo(computeValue())
    }
  }, [...updateIf, condition])

  return memo
}
