/**
 * Session State should be used for any saved global state, having it all
 * in one place greatly reduces session state issues.
 */
import { create as createStore } from "zustand"
import { persist } from "zustand/middleware"
import moment from "moment-timezone"

interface SessionState {
  isAuthenticated: boolean
  sessionToken?: string

  betaOn: boolean

  // TODO this will probably be in the useMe hook in the future
  timeZoneConfig: {
    timeZoneAbbr: string // e.g. PDT, EDT
    timeZoneName: string // "America/Los_Angeles"
    useLocalTime: boolean
  }

  setBetaOn: (betaOn: boolean) => any

  setIsAuthenticated: ((isAuthenticated: true, sessionToken?: string) => any) &
    ((isAuthenticated: false) => any)
}

export const useSessionState = createStore(
  persist<SessionState>(
    (set) => {
      const timeZoneName = moment.tz.guess()
      moment.tz.setDefault(timeZoneName)
      return {
        isAuthenticated: false,

        betaOn: false,

        timeZoneConfig: {
          timeZoneAbbr: moment().tz(timeZoneName).zoneAbbr(),
          timeZoneName,
          useLocalTime: true,
        },

        setBetaOn: (betaOn) => {
          set((state) => ({
            ...state,
            betaOn,
          }))
        },

        setIsAuthenticated: (isAuthenticated, sessionToken?: string) => {
          set((state) => ({
            ...state,
            isAuthenticated,
            sessionToken,
          }))
        },
      }
    },
    {
      name: "session-state",
    }
  )
)

useSessionState.subscribe(
  (state) => state.timeZoneConfig.timeZoneName,
  // @ts-expect-error because the second argument is deprecated / removed
  (timeZoneName) => {
    moment.tz.setDefault(timeZoneName)
  }
)
