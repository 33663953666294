import { useIsAuthenticated } from "core/hooks"
import { useSessionState } from "core/hooks"
import { useQuery } from "@tanstack/react-query"
import { useSignOut } from "./use-sign-out"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import moment from "moment-timezone"
import toast from "react-hot-toast"
import { useEffect } from "react"
import { isAxiosError } from "axios"
import { FullStory } from "@fullstory/browser"

export const useMe = () => {
  const isAuthenticated = useIsAuthenticated()
  const signOut = useSignOut()
  const timeZoneName = useSessionState((s) => s.timeZoneConfig.timeZoneName)
  useEffect(() => {
    moment.tz.setDefault(timeZoneName)
  }, [timeZoneName])

  return useQuery({
    queryKey: ["session"],
    queryFn: async () => {
      const axios = getAuthenticatedAxios()
      try {
        const {
          data: { signed_in_user },
        } = await axios.get(`/internal/sessions/get`)
        if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
          FullStory("setIdentity", {
            uid: signed_in_user.user_id,
            properties: {
              displayName: `${signed_in_user.first_name} ${signed_in_user.last_name}`,
              email: signed_in_user.email,
            },
          })
        }
        return signed_in_user
      } catch (e) {
        const seamHealthRes = await axios
          .get("/health")
          .catch(() => ({ data: { ok: false } }))

        if (!seamHealthRes.data.ok) {
          toast.error("Error getting session, Seam API is not responding", {
            id: "seam-api-down",
          })

          return
        }
        if (isAxiosError<{ error: { message: string } }>(e)) {
          console.error("Error getting session", e.response?.data)

          const isJWTMalformed =
            e.response?.data?.error?.message === "jwt malformed"

          if (isJWTMalformed) {
            toast.error(
              "Failed to get session! JWT is malformed. Logging you out..."
            )
            setTimeout(() => {
              signOut()
            }, 2000)
          }

          if (e.response?.status !== 401) {
            toast.error("Failed to get session!")
          }
        }
      }
    },
    enabled: isAuthenticated,
  })
}
